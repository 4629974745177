import type {
  PaletteMode,
  ThemeOptions,
} from '@mui/material'

const fontFamily = 'Space Grotesk Variable'
const typography = {
  fontFamily: fontFamily,
  h1: {
    fontFamily: fontFamily,
  },
  h2: {
    fontFamily: fontFamily,
  },
  h3: {
    fontFamily: fontFamily,
  },
  h4: {
    fontFamily: fontFamily,
  },
  h5: {
    fontFamily: fontFamily,
  },
  h6: {
    fontFamily: fontFamily,
    fontWeight: 700,
  },
}

const settings = {
  light: {
    palette: {
      mode: 'light',
      background: {
        default: '#FFFFFF',
      },
      primary: {
        main: '#033C59',
      },
      secondary: {
        main: '#F5A302',
      },
      text: {
        primary: '#000000',
        secondary: '#F5A302',
      },
    },
    typography,
    components: {},
  },
}

const getThemeOptions = (mode: PaletteMode) => settings['light'] as ThemeOptions

export default getThemeOptions
